import React from 'react';
import react from '../../Asserts/Image/react.png';
import node from '../../Asserts/Image/node.png';
import php from '../../Asserts/Image/php.png';
import python from '../../Asserts/Image/python.png';
import java from '../../Asserts/Image/java.png';



const HeroStrip = () => {
  return (
    <div className="bg-gradient-to-t from-pink-300  to-purple-500 flex justify-around items-center py-6 gap-60">
    <div className="bg-gray-300 rounded-full w-20 h-20"><img src={react} alt='node' className='w-20'  /> </div>
    <div className="bg-gray-300 rounded-full w-20 h-20"><img src={node} alt='node' className='w-20'  /> </div>
    <div className="bg-gray-300 rounded-full w-20 h-20"><img src={php} alt='node' className='w-20'  /></div>
    <div className="bg-gray-300 rounded-full w-20 h-20"><img src={python} alt='node' className='w-20'  /></div>
    <div className="bg-gray-300 rounded-full w-20 h-20"><img src={java} alt='node' className='w-20'  /></div>
  </div>)
}

export default HeroStrip
