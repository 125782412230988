import React from 'react'
import StudentFeedBack from './StudentFeedBack'
import StudentReviewForm from './StudentReviewForm'
import StudentFooter from './StudentFooter'
import HeroStrip from './HeroStrip'


const StudentFeedBackIndex = () => {
  return (
    <div>
      <StudentFeedBack/>
        <HeroStrip/>
      <StudentReviewForm/>
      <StudentFooter/>
    
    </div>
  )
}

export default StudentFeedBackIndex
